var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('label',{attrs:{"for":"checkdepositconfiguration_bridgeaccount"}},[_vm._v(_vm._s(_vm.$t('invoice.bank_accounts')))]),_c('e-select',{attrs:{"id":"bridgeaccount_id","track-by":"bridgeaccount_id","label":"bridgeaccount_name","placeholder":_vm.$t('check.selectionner_comptes'),"selectedLabel":_vm.$t('global.selected_label'),"options":_vm.bridge_accounts,"searchable":true,"loading":_vm.loading_accounts,"show-labels":false,"group-values":"accounts","group-label":"accountingplan_label","group-select":false,"multiple":true},model:{value:(_vm.bridges_accounts_selected),callback:function ($$v) {_vm.bridges_accounts_selected=$$v},expression:"bridges_accounts_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ask_bridge"}},[_vm._v(_vm._s(_vm.$t('invoice.ask_bridge'))+" ")]),_c('div',{staticClass:"input-group"},[_c('b-form-checkbox',{attrs:{"id":"ask_bridge"},model:{value:(_vm.ask_bridge),callback:function ($$v) {_vm.ask_bridge=$$v},expression:"ask_bridge"}})],1)])])]),_c('DateRangeV2',{attrs:{"start":_vm.start_date,"end":_vm.end_date,"ShowTodayButton":true,"dateSelect":true,"periodToShow":['semaine_derniere','jour','mois','mois_dernier','personalise']},on:{"update:start":function($event){_vm.start_date=$event},"update:end":function($event){_vm.end_date=$event},"submit":_vm.loadTransactions}}),_c('CustomTable',{ref:"bridge_transactions",attrs:{"id_table":"bridge_transactions","items":_vm.bridge_transactions,"busy":_vm.table_busy,"primaryKey":"bridgetransaction_id","hide_if_empty":true,"externSlotColumns":_vm.extern_slot_columns},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(account_name)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.bridge_account.bridge_bank.bridgebank_name)+" - "+_vm._s(data.bridge_account.bridgeaccount_name)),_c('br'),_vm._v(" "+_vm._s(data.bridge_account.bridgeaccount_iban)+" ")]}},{key:"custom-slot-cell(invoices)",fn:function(ref){
var data = ref.data;
return _vm._l((data.invoices),function(invoice,key){return _c('div',{key:key},[(invoice.current_account_id)?[_vm._v(_vm._s(_vm.$t('payment.compte_courant')))]:_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.quickPreview(invoice)}}},[_vm._v(" "+_vm._s(invoice.num)+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'eye']}})],1)],2)})}},{key:"custom-slot-cell(bridgetransaction_amount)",fn:function(ref){
var data = ref.data;
return [_c('PriceFormat',{class:data.style,attrs:{"pathArr":data,"price":data.bridgetransaction_amount,"currency":"bridgetransaction_currency","symbol":true}})]}},{key:"custom-slot-cell(tiers.tiers_rs)",fn:function(ref){
var data = ref.data;
return [(data.tiers)?_c('router-link',{attrs:{"to":{ name: 'tiersFiche', params: { tiers_id: data.tiers.tiers_id }}}},[_vm._v(_vm._s(data.tiers.tiers_rs))]):_vm._e()]}}],null,true)}),_c('b-modal',{ref:"modelPreview",attrs:{"size":"xl","hide-footer":""},on:{"hidden":function($event){_vm.pdf_url = null; _vm.base64 = null}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("action.previsualiser"))+" ")]},proxy:true}])},[(_vm.pdf_url != null)?_c('iframe',{staticStyle:{"position":"relative"},attrs:{"src":_vm.pdf_url,"height":"1000px","width":"100%"}}):(_vm.base64 != null)?_c('iframe',{staticStyle:{"position":"relative"},attrs:{"src":'data:application/pdf;base64,' + _vm.base64,"height":"1000px","width":"100%"}}):_c('div',[_c('LoadingSpinner')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }